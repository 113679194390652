import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6541ef97 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _189f7d15 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _138a4f3b = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _a7dec8ee = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _2f503392 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _77609d2b = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _2b5a493e = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _c0bb45e2 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _7edc0801 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _411e5697 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _1f6e22c4 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _0b4330f0 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _49c93988 = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _603afa74 = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _68a598e4 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _3afc47e4 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _b6b09ae6 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _cd0e4aac = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _7f218428 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _d3eec78e = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _b29c72aa = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _6541ef97,
    name: "kits"
  }, {
    path: "/lojas",
    component: _189f7d15,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _138a4f3b,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _a7dec8ee,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _2f503392,
    name: "motos"
  }, {
    path: "/reset",
    component: _77609d2b,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _2b5a493e,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _c0bb45e2,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _7edc0801,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _411e5697,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _1f6e22c4,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _0b4330f0,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _49c93988,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _603afa74,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _68a598e4,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _3afc47e4,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _b6b09ae6,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _cd0e4aac,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _7f218428,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _d3eec78e,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _b29c72aa,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
